@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Calibri:400,700,400italic,700italic');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: rgb(23, 37, 84);
}

::selection {
    @apply bg-copyright text-black;
}

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(107, 114, 128, 1) rgba(30, 58, 138 , 1);
    scroll-behavior: smooth;
}

/* WebKit and Chromiums */
::-webkit-scrollbar {
    @apply w-2.5 h-3 transition-all;
}

::-webkit-scrollbar-button {
	@apply w-0 h-0;
}

::-webkit-scrollbar-thumb {
	@apply bg-gray-500 border-0 rounded-full;
}

::-webkit-scrollbar-thumb:hover {
	@apply bg-gray-400;
}

::-webkit-scrollbar-thumb:active {
	@apply bg-gray-400;
}

::-webkit-scrollbar-track {
	@apply bg-blue-900 border-0 rounded-full;
}

::-webkit-scrollbar-track:hover {
	@apply bg-blue-900;
}

::-webkit-scrollbar-track:active {
	@apply bg-blue-900;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

html, body {
    @apply font-roboto;
}

.data-table-extensions {
    @apply px-5 pb-5;
}

.data-table-extensions .data-table-extensions-filter .filter-text {
    @apply bg-gray-50 border border-gray-300 transition-all text-gray-900 sm:text-sm rounded focus:ring-cyan-600 focus:border-cyan-600 block w-full px-2 py-1 max-w-xs outline-none;
}

.rdt_Table .rdt_TableHeadRow {
    background: rgba(30, 58, 138, 1);
    @apply text-white text-xs font-medium uppercase;
}

.rdt_Table .rdt_TableBody .rdt_TableRow {
    @apply text-sm text-gray-900;
}

@media screen and (max-width: 1024px) {
    .hidden-sombra{
        display: none;
    }

    .flex-sombra{
        display: flex;
    }
}

.btn-logout {
    @apply cursor-pointer transition-colors hover:bg-red-600;
}

#login-form input,
#add-student-register input,
#add-student-register select  {
    @apply outline-none border-2 border-gray-300 focus:border-blue-cnx focus:ring-blue-cnx;
}

/* body.swal2-shown > [aria-hidden="true"] {
    @apply filter blur transition-all duration-100;
} */

.language-item {
    @apply bg-purple-cnx text-white px-4 py-2 rounded-xl mx-1 text-base font-bold;
}

.language-item-blue {
    @apply bg-blue-cnx text-white px-4 py-2 rounded-xl mx-1 text-base font-bold;
}

.btn-card-header {
    @apply inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-blue-950 focus:ring-4 focus:outline-none rounded-lg;
}

.file-icon {
    @apply bg-opacity-25 w-14 h-14 flex items-center justify-center mr-3 text-base rounded-full font-bold;
}

.file-details .file-title {
    @apply font-bold text-lg;
}

.file-details .file-description {
    @apply text-sm text-gray-500;
}

.btn-file {
    @apply inline-flex items-center px-4 py-2 text-lg font-medium text-center focus:ring-4 focus:outline-none rounded-lg transition-all overflow-hidden;
}

.btn-download {
    @apply text-white bg-purple-cnx;
}

.btn-delete {
    @apply text-white bg-red-600;
}

.btn-file .btn-icon {
    @apply transition
}

.btn-file .btn-label-hidden {
    @apply max-w-0 opacity-0 max-h-4 whitespace-nowrap transition-all duration-300 hover:max-w-full hover:opacity-100;
}

ul.file-action-buttons {
    @apply p-0 text-center;
}

.file-action-buttons li {
    @apply bg-yellow-600 list-none inline-block rounded-full mx-1 overflow-hidden;
}

.file-action-buttons li a {
    @apply block p-2 min-w-[1.28571429em] min-h-[1.28571429em] h-[1.28571429em] whitespace-nowrap leading-normal transition-all duration-500;
}

.file-action-buttons li:hover a {
    @apply max-w-[200px] pr-4;
}

#file-drag-psychological-profile {
    @apply transition-colors hover:border-blue-cnx hover:bg-gray-200;
}

#file-drag-psychological-profile:hover .file-drag-icon,
#file-drag-psychological-profile:hover .file-drag-title {
    @apply text-blue-cnx transition-colors;
}

/* #file-drag-psychological-profile .file-drag-icon {
    @apply text-6xl text-gray-400;
} */

.profile-card {
    @apply relative flex flex-col items-center rounded-lg bg-white;
}

.profile-card-image {
    @apply w-full h-48;
}

.profile-card-image img {
    @apply w-full h-full object-cover rounded-t-lg;
}

.profile-card-avatar {
    @apply absolute top-16 lg:top-20 xl:top-16 w-48 h-48 md:w-44 md:h-44 lg:w-40 lg:h-40 xl:w-48 xl:h-48 rounded-full bg-white flex justify-center items-center;
}

.profile-card-avatar img {
    @apply w-[92%] h-auto rounded-full;
}

.profile-card-title {
    @apply mt-16 font-bold text-lg text-gray-900 text-center;
}

.profile-card-subtitle {
    @apply mt-2 text-base;
}

.tooltip {
    @apply absolute text-base font-bold bg-purple-cnx text-white px-4 py-2 rounded-lg opacity-0 pointer-events-none transition-all duration-300 top-[70%] z-10;
}

#recommend-letter:not(.tooltip) + .tooltip {
    @apply top-[60%] -left-3;
}

#commitment-letter:not(.tooltip) + .tooltip {
    @apply top-[60%] left-8;
}

#curriculum:not(.tooltip) + .tooltip {
    @apply top-[60%] left-20;
}

.tooltip::before {
    content: '';
    @apply absolute bg-purple-cnx -bottom-1 left-1/2 transition transform -translate-x-1/2 w-3 h-3 rotate-45;
}

/* Activamos el tooltip cuando el elemento que no sea el tooltip tenga el hover*/
.profile-card-item:not(.tooltip):hover + .tooltip,
#recommend-letter:not(.tooltip):hover + .tooltip,
#commitment-letter:not(.tooltip):hover + .tooltip,
#curriculum:not(.tooltip):hover + .tooltip {
/*#commitment-letter:not(.tooltip):hover + .tooltip,
#curriculum:not(.tooltip):hover + .tooltip, {*/
    @apply opacity-100 pointer-events-auto visible;
}

.accordion {
    @apply flex justify-between items-center font-medium cursor-pointer list-none bg-blue-cnx text-white p-2 rounded-lg hover:bg-blue-900 transition-all;
}

.blue-circle{
    animation-delay: 0.1s;
}
.green-circle{
    animation-delay: 0.2s;
}
.red-circle{
    animation-delay: 0.3s;
}

.btn-login {
    @apply text-white transition-colors bg-blue-cnx hover:bg-blue-950 focus:ring-4 focus:ring-cyan-200 font-medium rounded-xl text-base px-10 py-3 w-full sm:w-auto text-center disabled:opacity-50 disabled:cursor-not-allowed;
}

form .alert {
    @apply text-sm;
}

.checkbox-multiple {
    accent-color: rgba(30, 58, 138, 1);
}

#login-form .login-input {
    @apply bg-gray-50 border-2 border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-cyan-600 focus:border-cyan-600 block w-full p-2.5 disabled:opacity-50 disabled:cursor-not-allowed;
}

.sombra{
    background-color: rgba(214, 214, 214, 0.7);
}

.btn-add {
    @apply inline-block bg-slate-50 w-[60px] h-[60px] float-left my-0 mx-1 rounded-lg;
}

.btn-add .btn-add-icon {
    @apply inline-block w-[60px] h-[60px] rounded-lg box-border;
    line-height: 60px;
}

.sidebar-nav {
    @apply bg-blue-950 text-white;
    margin-top: 0px !important;
}

.sidebar-dropdown {
    @apply my-1.5;
}

.sidebar-dropdown .sidebar-content {
    @apply flex items-center px-3 py-1 transition-colors cursor-pointer rounded-lg hover:bg-blue-900 hover:bg-opacity-50 select-none;
}

/*  aplicamos la clase .active al elemento que tenga la clase .sidebar-content */
.sidebar-dropdown .sidebar-content.active {
    @apply bg-blue-900 bg-opacity-50;
}

.sidebar-content .sidebar-title {
    @apply my-2 flex text-sm font-bold uppercase text-left;
}

.sidebar-content .sidebar-icon {
    @apply text-white text-sm ml-2 my-2 transition-transform;
}

.sidebar-menu {
    @apply text-sm text-white transition-colors font-bold rounded-lg flex items-center p-2 my-1 border-2 border-transparent hover:bg-blue-900 w-full;
}

/* Aplicamos estilos  a los elementos que tengan la clase .sidebar-menu y la clase .current */
.sidebar-menu.current {
    @apply border-sky-500;
}

.sidebar-menu .ping {
    @apply invisible;
}

.sidebar-menu.current .ping {
    @apply visible;
}

#sidebar-container {
    @apply h-auto overflow-y-auto;
}

#reactSchedulerOutsideWrapper {
    position: relative !important;
    @apply rounded-lg;
}

.btn-base {
    @apply text-black bg-slate-50 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center hover:bg-gray-300 transition-colors;
}

.btn-card {
    @apply text-white bg-blue-950 focus:ring-4 focus:ring-cyan-200 font-medium inline-flex items-center justify-center rounded-lg text-sm px-3 py-2 text-center hover:bg-blue-900 transition-colors;
}

.btn-modal {
    @apply w-full bg-blue-cnx hover:bg-blue-950 mb-2 py-2 rounded-lg text-white text-center transition-colors font-semibold text-base disabled:opacity-50 disabled:cursor-not-allowed;
}

.btn-modal-select {
    @apply bg-blue-950;
}

#commitment-letter-preview::-webkit-pdf-toolbar {
    display: none;
}

